// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("local-time").start();
require("@rails/ujs").start();
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("packs/autosave");
const Choices = require("choices.js");

document.addEventListener("DOMContentLoaded", function () {
  const assignmentSelect = document.getElementById("review_dup_assignment_id");
  const timeslotConversions = document.querySelectorAll(
    "#timeslot-conversions time"
  );
  const addLikertButton = document.getElementById("add-likert-value");

  const addLikertChangeListener = () => {
    (document.querySelectorAll("input.likert-values-key") || []).forEach(
      ($element) => {
        $element.addEventListener("change", (event) => {
          let likertValue = document.getElementById(
            event.target.dataset.likertValueKeyId
          );

          likertValue.setAttribute(
            "name",
            "question[likert_values[" + event.target.value + "]]"
          );
        });
      }
    );
  };

  const removeLikertChangeListener = () => {
    (document.querySelectorAll(".remove-likert-fields") || []).forEach(
      ($element) => {
        $element.addEventListener("click", (event) => {
          event.preventDefault();

          let likertIndex = event.target.dataset.index;
          let likertValueField = document.getElementById(
            "likert-values-field-" + likertIndex
          );
          let likertTextArea = document.getElementById(
            "likert-values-value-" + likertIndex
          );

          likertTextArea.disabled = true;
          likertValueField.classList.add("is-hidden");
        });
      }
    );
  };

  const initializeEditor = () => {
    (document.querySelectorAll(".editor") || []).forEach(($element) => {
      ClassicEditor.create($element).catch((error) => {
        console.error(error);
      });

      $element.classList.remove("editor");
    });
  };

  const initializeforms = () => {
    addLikertChangeListener();
    removeLikertChangeListener();
    initializeEditor();
  };

  const hideLikertTypeFields = (likertTypeValue) => {
    (document.querySelectorAll("[data-question-type]") || []).forEach(
      ($element) => {
        if ($element.dataset.questionType == likertTypeValue) {
          $element.classList.remove("is-hidden");
        } else {
          $element.classList.add("is-hidden");
        }
      }
    );
  };

  for (let i = 0; i < timeslotConversions.length; i++) {
    assignmentSelect.innerHTML = assignmentSelect.innerHTML.replace(
      new RegExp(timeslotConversions[i].dateTime, "g"),
      timeslotConversions[i].innerHTML
    );
  }

  (document.querySelectorAll(".autocomplete") || []).forEach(($element) => {
    new Choices($element, {
      position: "bottom",
      searchPlaceholderValue: "Type to search",
    });
  });

  (document.querySelectorAll(".multi-select") || []).forEach(($element) => {
    new Choices($element, {
      removeItemButton: true,
      position: "bottom",
    });
  });

  (document.querySelectorAll(".add-remove-tags") || []).forEach(($element) => {
    new Choices($element, {
      removeItemButton: true,
    });
  });

  initializeforms();

  if (addLikertButton !== null) {
    addLikertButton.addEventListener("click", (event) => {
      event.preventDefault();

      let likertInputs = event.target.dataset.likertInputs;
      let likerInputsIndex = document.querySelectorAll(
        ".likert-values-fields"
      ).length;
      let indexedLikertInputs = likertInputs.replace(
        /_INDEX_/g,
        likerInputsIndex
      );

      document
        .getElementById("likert-values")
        .insertAdjacentHTML("beforeend", indexedLikertInputs);

      initializeforms();
    });
  }

  const questionTypeField = document.getElementById("question_question_type");

  if (questionTypeField !== null) {
    hideLikertTypeFields(questionTypeField.value);

    questionTypeField.addEventListener("change", (event) => {
      hideLikertTypeFields(event.target.value);
    });
  }
  const el = document.getElementById("select_all");
  if (el) {
    el.addEventListener("change", (event) => {
      let checked = event.target.checked;

      (document.getElementsByName("assignment_ids[]") || []).forEach(
        ($element) => {
          $element.checked = checked;
        }
      );
    });
  }

  document
    .querySelector('input[value="no additional traits demonstrated"]')
    .addEventListener("change", (event) => {
      const checkbox = event.target;
      const checked = checkbox.checked;

      if (checked) {
        const questionDiv = checkbox.closest(".question");
        const neighborCheckboxes = questionDiv.querySelectorAll(
          'input[type="checkbox"]'
        );
        neighborCheckboxes.forEach((box) => {
          if (box !== checkbox) {
            box.checked = !checked;
          }
        });
      }
    });

  document
    .querySelectorAll(
      'input[type="checkbox"]:not([value="no additional traits demonstrated"])'
    )
    .forEach((checkbox) => {
      checkbox.addEventListener("click", (event) => {
        console.log("changer");
        const checkedBox = event.target,
          parent = checkedBox.closest(".question");
        if (checkedBox.checked === true) {
          const neighborBox = parent.querySelector(
            'input[value="no additional traits demonstrated"]'
          );
          if (neighborBox) {
            neighborBox.checked = false;
          }
        }
      });
    });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
